{
    "Home": "Acasă",
    "Books": "Cărți",
    "About": "Despre mine",
    "Contact": "Contact",
    "English": "Engleză",
    "Romanian": "Română",
    "Page not found": "Pagina nu a fost găsită",
    "Not found": "Nu a fost găsit",
    "Open main menu": "Deschideți meniul principal",
    "Redirecting to": "Redirecționare către",
    "Book Details": "Detalii carte",
    "Book Language": "Limba cărții",
    "Edition": "Ediția",
    "Publication year": "Anul publicației",
    "Buy Now": "Cumpără",
    "more": "mai mult",
    "less": "mai puțin",
    "print": "tipar",
    "e-book": "e-book",
    "All Rights Reserved": "Toate drepturile rezervate",
    "Latest Books": "Ultimele cărți",
    "See all books": "Vezi toate cărțile",
    "Email": "Email",
    "Name": "Nume",
    "Your name": "Numele dvs.",
    "Message": "Mesaj",
    "Leave a message": "Lăsați un mesaj",
    "Send message": "Trimite mesaj",
    "Meet Ezechel Suciu": "Fă cunoștință cu Ezechel Suciu",
    "Connect on Facebook": "Conectează-te pe Facebook"
}
