import { Elm } from './Main.elm';
import * as serviceWorker from './serviceWorker';
import 'flowbite';

import * as translationsEn from './Locale/translations.en.json';
import * as translationsRo from './Locale/translations.ro.json';

import logo from './../public/logo.png';
import ezs from './../public/ezechel-suciu.jpg';
import coverCuEvanghelia from './../public/book-cover/cu-evanghelia.jpg';
import coverFromJerusalem from './../public/book-cover/from-jerusalem.jpg';
import coverInCautareaFericirii from './../public/book-cover/in-cautarea-fericirii.jpg';
import coverIubitiPeDomnul from './../public/book-cover/iubiti-pe-domnul.jpg';
import coverPeUrmeleDuhuluiSfantVol1 from './../public/book-cover/pe-urmele-duhului-sfant-vol1.jpg';
import coverPeUrmeleDuhuluiSfantVol2 from './../public/book-cover/pe-urmele-duhului-sfant-vol2.jpg';
import coverSabatul from './../public/book-cover/sabatul.jpg';
import coverSabbath from './../public/book-cover/sabbath.jpg';
import coverTrezesteTeTuCareDormi from './../public/book-cover/trezeste-te-tu-care-dormi.jpg';
import coverUndeSuntCeiCeNuMaiSunt from './../public/book-cover/unde-sunt-cei-ce-nu-mai-sunt.jpg';

import spiralaSaptamanilorRo from './../public/short-url/1_spirala_saptamanilor_ro.jpeg';
import sabatulRo from './../public/short-url/2_sabatul_ro.jpeg';
import panoramaMortiiRo from './../public/short-url/3_panorama_mortii_ro.jpeg';
import relatiaDintrePrincipaleleLegaminteRo from './../public/short-url/4_relatia_dintre_principalele_legaminte_ro.jpeg';
import panoramaRelatilorRo from './../public/short-url/5_panorama_relatilor_ro.jpeg';

const lsLangKey = "ezs-language";
const lsThemeKey = "ezs-theme";

const app = Elm.Main.init({
  node: document.getElementById('root'),
  flags: {
    language: getLanguage(),
    translationsEn,
    translationsRo,
    theme: getTheme(),
    images: {
      logo,
      ezs,
      coverCuEvanghelia,
      coverFromJerusalem,
      coverInCautareaFericirii,
      coverIubitiPeDomnul,
      coverPeUrmeleDuhuluiSfantVol1,
      coverPeUrmeleDuhuluiSfantVol2,
      coverSabatul,
      coverSabbath,
      coverTrezesteTeTuCareDormi,
      coverUndeSuntCeiCeNuMaiSunt,
      spiralaSaptamanilorRo,
      sabatulRo,
      panoramaMortiiRo,
      relatiaDintrePrincipaleleLegaminteRo,
      panoramaRelatilorRo,
    },
  },
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// app.ports.outbound.subscribe(language => {
//   localStorage.setItem("ezs-language", language)
// })

function getLanguage() {
  return (localStorage && localStorage.getItem(lsLangKey)) || navigator.language || navigator.userLanguage;
}

app.ports.saveLanguageSelection.subscribe((language) => {
  if (localStorage) {
    localStorage.setItem(lsLangKey, language);
    console.log("Language selection persisted:", language);
  } else {
    console.log("Local storage is not available to cache user selected language");
  }
});

app.ports.closeLanguageDropdown.subscribe(() => {
  document.getElementById("language-dropdown-button").click();
});

app.ports.closeMenu.subscribe(() => {
  setTimeout(function(){
    document.getElementById("menu-button").click();
  }, 200);
});

function getTheme() {
  if (localStorage && localStorage.getItem(lsThemeKey)) {
    return localStorage.getItem(lsThemeKey);
  }

  return window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
}

app.ports.changeTheme.subscribe((theme) => {
  if (theme === "dark") {
    document.getElementsByTagName('html')[0].classList.add('dark');
  } else if (theme === "light") {
    document.getElementsByTagName('html')[0].classList.remove('dark');
  } else {
    console.log("Theme not recognized: ", theme);
  }

  console.log("Changed theme to:", theme);
});

app.ports.saveThemeSelection.subscribe((theme) => {
  if (localStorage) {
    localStorage.setItem(lsThemeKey, theme);
    console.log("Theme selection persisted:", theme);
  } else {
    console.log("Local storage is not available to cache user selected theme");
  }
});

app.ports.scrollToTop.subscribe(() => {
  window.scrollTo(0, 0);
});
