{
    "Home": "Home",
    "Books": "Books",
    "About": "About",
    "Contact": "Contact",
    "English": "English",
    "Romanian": "Romanian",
    "Page not found": "Page not found",
    "Not found": "Not found",
    "Open main menu": "Open main menu",
    "Redirecting to": "Redirecting to",
    "Book Details": "Book Details",
    "Book Language": "Book Language",
    "Edition": "Edition",
    "Publication year": "Publication year",
    "Buy Now": "Buy Now",
    "more": "more",
    "less": "less",
    "print": "print",
    "e-book": "e-book",
    "All Rights Reserved": "All Rights Reserved",
    "Latest Books": "Latest Books",
    "See all books": "See all books",
    "Email": "Email",
    "Name": "Name",
    "Your name": "Your name",
    "Message": "Message",
    "Leave a message": "Leave a message",
    "Send message": "Send message", 
    "Meet Ezechel Suciu": "Meet Ezechel Suciu",
    "Connect on Facebook": "Connect on Facebook"
}
