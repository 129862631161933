port module Main exposing (..)

import Browser exposing (Document, UrlRequest)
import Browser.Navigation as Navigation exposing (load)
import GlobalModel exposing (Images, imagesDecoder)
import Html exposing (Html, a, button, div, footer, h2, img, li, nav, span, text, ul)
import Html.Attributes exposing (alt, attribute, class, href, id, src, target, title, type_)
import Html.Events exposing (onClick)
import I18Next
    exposing
        ( Delims(..)
        , Translations
        , t
        , translationsDecoder
        )
import I18n exposing (Lang(..), resolveUserLanguage, toLocale)
import Json.Decode as Decode exposing (Value)
import Pages.About as About
import Pages.Book as Book
import Pages.Books as Books
import Pages.Contact as Contact
import Pages.Home as Home
import Pages.ShortUrl as ShortUrl
import Routes exposing (Route, senseLanguageFromUrlPath, translateUrl)
import Url exposing (Url)
import View.Button exposing (facebookButtonIconOnly)
import View.Svg exposing (facebookIcon, flagEnLarge, flagEnSmall, flagRoLarge, flagRoSmall, menuIcon, moonIcon, sunIcon)


port saveLanguageSelection : String -> Cmd msg


port closeLanguageDropdown : () -> Cmd msg


port closeMenu : () -> Cmd msg


port changeTheme : String -> Cmd msg


port saveThemeSelection : String -> Cmd msg


port scrollToTop : () -> Cmd msg



---- MODEL ----


type Page
    = Home Home.Model
    | Books Books.Model
    | Book Book.Model
    | About About.Model
    | Contact Contact.Model
    | ShortUrl ShortUrl.Model
    | NotFound


type alias Model =
    { currentLanguage : Lang
    , languageFromUser : Lang
    , translations : Translations
    , translationsEn : Result Decode.Error I18Next.Translations
    , translationsRo : Result Decode.Error I18Next.Translations
    , theme : Theme
    , images : Images
    , page : Page
    , url : Url.Url
    , navigationKey : Navigation.Key
    }


type Theme
    = Light
    | Dark


themeToString : Theme -> String
themeToString theme =
    case theme of
        Light ->
            "light"

        Dark ->
            "dark"


stringToTheme : String -> Maybe Theme
stringToTheme theme =
    case theme of
        "light" ->
            Just Light

        "dark" ->
            Just Dark

        _ ->
            Nothing


type alias Flags =
    { language : Value
    , translationsEn : Value
    , translationsRo : Value
    , theme : Value
    , images : Value
    }


initialModel :
    Result Decode.Error String
    -> Result Decode.Error I18Next.Translations
    -> Result Decode.Error I18Next.Translations
    -> Result Decode.Error String
    -> Images
    -> Url.Url
    -> Navigation.Key
    -> Model
initialModel userLanguage translationsEn translationsRo theme images url navigationKey =
    let
        initialLanguage =
            resolveUserLanguage userLanguage

        initialTheme =
            case theme of
                Ok ot ->
                    Maybe.withDefault Light (stringToTheme ot)

                Err _ ->
                    Light
    in
    { currentLanguage = initialLanguage
    , languageFromUser = initialLanguage
    , translations = chooseTranslations initialLanguage translationsEn translationsRo
    , translationsEn = translationsEn
    , translationsRo = translationsRo
    , theme = initialTheme
    , images = images
    , page = NotFound
    , url = url
    , navigationKey = navigationKey
    }


chooseTranslations :
    Lang
    -> Result Decode.Error I18Next.Translations
    -> Result Decode.Error I18Next.Translations
    -> I18Next.Translations
chooseTranslations currentLanguage translationsEn translationsRo =
    let
        currentTranslations =
            case currentLanguage of
                En ->
                    translationsEn

                Ro ->
                    translationsRo
    in
    case currentTranslations of
        Ok translations ->
            translations

        Err _ ->
            I18Next.initialTranslations


init : Flags -> Url -> Navigation.Key -> ( Model, Cmd Msg )
init flags url navigationKey =
    let
        userLanguage =
            flags.language
                |> Decode.decodeValue Decode.string

        translationsEn =
            flags.translationsEn
                |> Decode.decodeValue translationsDecoder

        translationsRo =
            flags.translationsRo
                |> Decode.decodeValue translationsDecoder

        theme =
            flags.theme
                |> Decode.decodeValue Decode.string

        images =
            let
                imagesResult =
                    flags.images
                        |> Decode.decodeValue imagesDecoder
            in
            case imagesResult of
                Ok validImages ->
                    validImages

                Err _ ->
                    { logo = "logo_image_not_found"
                    , ezs = "ezs_image_not_found"
                    , coverCuEvanghelia = "coverCuEvanghelia_image_not_found"
                    , coverFromJerusalem = "coverFromJerusalem_image_not_found"
                    , coverInCautareaFericirii = "coverInCautareaFericirii_image_not_found"
                    , coverIubitiPeDomnul = "coverIubitiPeDomnul_image_not_found"
                    , coverPeUrmeleDuhuluiSfantVol1 = "coverPeUrmeleDuhuluiSfantVol1_image_not_found"
                    , coverPeUrmeleDuhuluiSfantVol2 = "coverPeUrmeleDuhuluiSfantVol2_image_not_found"
                    , coverSabatul = "coverSabatul_image_not_found"
                    , coverSabbath = "coverSabbath_image_not_found"
                    , coverTrezesteTeTuCareDormi = "coverTrezesteTeTuCareDormi_image_not_found"
                    , coverUndeSuntCeiCeNuMaiSunt = "coverUndeSuntCeiCeNuMaiSunt_image_not_found"
                    , spiralaSaptamanilorRo = "spiralaSaptamanilorRo_image_not_found"
                    , sabatulRo = "sabatulRo_image_not_found"
                    , panoramaMortiiRo = "panoramaMortiiRo_image_not_found"
                    , relatiaDintrePrincipaleleLegaminteRo = "relatiaDintrePrincipaleleLegaminteRo_image_not_found"
                    , panoramaRelatilorRo = "panoramaRelatilorRo_image_not_found"
                    }

        model =
            initialModel userLanguage translationsEn translationsRo theme images url navigationKey

        maybeRoute =
            Routes.match url

        updatedModel =
            updateSensedLanguageInModel model maybeRoute
    in
    setNewPage maybeRoute updatedModel [ changeTheme (themeToString updatedModel.theme) ]



---- UPDATE ----


type Msg
    = ChangeLanguage Lang
    | ChangeTheme Theme
    | ClickMenuElement
    | ChangeUrl Url
    | ClickLink UrlRequest
    | HomeMsg Home.Msg
    | BooksMsg Books.Msg
    | BookMsg Book.Msg
    | AboutMsg About.Msg
    | ContactMsg Contact.Msg
    | ShortUrlMsg ShortUrl.Msg


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    case ( msg, model.page ) of
        ( ChangeLanguage language, _ ) ->
            let
                updatedModel =
                    updateLanguageInModel model language

                translatedUrl =
                    translateUrl updatedModel.currentLanguage updatedModel.url
            in
            ( { updatedModel | url = translatedUrl }
            , Cmd.batch
                [ saveLanguageSelection (toLocale language)
                , Navigation.pushUrl model.navigationKey (Url.toString translatedUrl)
                , closeLanguageDropdown ()
                ]
            )

        ( ChangeTheme theme, _ ) ->
            ( { model | theme = theme }
            , Cmd.batch
                [ saveThemeSelection (themeToString theme)
                , changeTheme (themeToString theme)
                ]
            )

        ( ClickMenuElement, _ ) ->
            ( model, closeMenu () )

        ( ChangeUrl url, _ ) ->
            let
                updatedModelWithUrl =
                    { model | url = url }

                maybeRoute =
                    Routes.match url

                updatedModelWithLang =
                    updateSensedLanguageInModel updatedModelWithUrl maybeRoute
            in
            setNewPage maybeRoute updatedModelWithLang [ scrollToTop () ]

        ( ClickLink (Browser.Internal url), _ ) ->
            ( { model | url = url }, Navigation.pushUrl model.navigationKey (Url.toString url) )

        ( ClickLink (Browser.External url), _ ) ->
            ( model, load url )

        ( HomeMsg homeMsg, Home homeModel ) ->
            Home.update homeMsg homeModel
                |> processPageUpdate Home HomeMsg model

        ( BooksMsg booksMsg, Books booksModel ) ->
            Books.update booksMsg booksModel
                |> processPageUpdate Books BooksMsg model

        ( BookMsg bookMsg, Book bookModel ) ->
            Book.update bookMsg bookModel
                |> processPageUpdate Book BookMsg model

        ( AboutMsg aboutMsg, About aboutModel ) ->
            About.update aboutMsg aboutModel
                |> processPageUpdate About AboutMsg model

        ( ContactMsg contactMsg, Contact contactModel ) ->
            Contact.update contactMsg contactModel
                |> processPageUpdate Contact ContactMsg model

        ( ShortUrlMsg shortUrlMsg, ShortUrl shortUrlModel ) ->
            ShortUrl.update shortUrlMsg shortUrlModel
                |> processPageUpdate ShortUrl ShortUrlMsg model

        _ ->
            ( model, Cmd.none )


updateLanguageInModel : Model -> Lang -> Model
updateLanguageInModel model language =
    { model
        | currentLanguage = language
        , translations = chooseTranslations language model.translationsEn model.translationsRo
    }


updateSensedLanguageInModel : Model -> Maybe Route -> Model
updateSensedLanguageInModel model maybeRoute =
    let
        sensedLanguageFromUrlPath =
            case senseLanguageFromUrlPath model.url.path maybeRoute of
                Just validSensedLanguage ->
                    validSensedLanguage

                Nothing ->
                    model.currentLanguage
    in
    updateLanguageInModel model sensedLanguageFromUrlPath


setNewPage : Maybe Routes.Route -> Model -> List (Cmd Msg) -> ( Model, Cmd Msg )
setNewPage maybeRoute model commands =
    let
        ( newModel, newCommand ) =
            case maybeRoute of
                Just Routes.Home ->
                    Home.init
                        |> processPageUpdate Home HomeMsg model

                Just Routes.Books ->
                    Books.init
                        |> processPageUpdate Books BooksMsg model

                Just (Routes.Book bookId) ->
                    Book.init bookId
                        |> processPageUpdate Book BookMsg model

                Just Routes.About ->
                    About.init
                        |> processPageUpdate About AboutMsg model

                Just Routes.Contact ->
                    Contact.init
                        |> processPageUpdate Contact ContactMsg model

                Just (Routes.ShortUrl token) ->
                    ShortUrl.init token
                        |> processPageUpdate ShortUrl ShortUrlMsg model

                Nothing ->
                    ( { model | page = NotFound }, Cmd.none )
    in
    ( newModel, Cmd.batch (newCommand :: commands) )


processPageUpdate : (pageModel -> Page) -> (pageMsg -> Msg) -> Model -> ( pageModel, Cmd pageMsg ) -> ( Model, Cmd Msg )
processPageUpdate createPage wrapMsg model ( pageModel, pageCmd ) =
    ( { model | page = createPage pageModel }
    , Cmd.map wrapMsg pageCmd
    )



---- VIEW ----


view : Model -> Document Msg
view model =
    let
        ( title, content ) =
            viewContent model
    in
    { title = title
    , body =
        [ div [ class "dark:text-gray-200 bg-stone-900 dark:bg-neutral-900" ]
            [ viewNavbar model
            , div [ class "pt-10 mx-auto" ] [ content ]
            , viewFooter model
            ]
        ]
    }


viewContent : Model -> ( String, Html Msg )
viewContent model =
    case model.page of
        Home homeModel ->
            ( t model.translations "Home", Home.view model homeModel |> Html.map HomeMsg )

        Books booksModel ->
            ( t model.translations "Books", Books.view model booksModel |> Html.map BooksMsg )

        Book bookModel ->
            let
                titleSuffix =
                    case bookModel.book of
                        Just book ->
                            " - " ++ book.title

                        Nothing ->
                            ""
            in
            ( t model.translations "Book Details" ++ titleSuffix, Book.view model bookModel |> Html.map BookMsg )

        About aboutModel ->
            ( t model.translations "About", About.view model aboutModel |> Html.map AboutMsg )

        Contact contactModel ->
            ( t model.translations "Contact", Contact.view model contactModel |> Html.map ContactMsg )

        ShortUrl shortUrlModel ->
            ( shortUrlModel.token, ShortUrl.view model shortUrlModel |> Html.map ShortUrlMsg )

        NotFound ->
            ( t model.translations "Page not found", viewNotFound model )


viewNotFound : Model -> Html Msg
viewNotFound model =
    div [ class "text-center pb-8" ]
        [ h2 [ class "text-xl text-neutral-50 dark:text-neutral-300" ] [ text (t model.translations "Not found") ]
        ]


viewNavbar : Model -> Html Msg
viewNavbar model =
    nav [ class "bg-neutral-50 dark:bg-neutral-950 border-gray-200" ]
        [ div [ class "max-w-screen-2xl flex flex-wrap items-center justify-between mx-auto p-4" ]
            [ a [ href "/", class "flex items-center" ]
                [ span [ class "self-center text-2xl font-semibold font-sans tracking-normal whitespace-nowrap text-neutral-950 dark:text-neutral-300" ] [ text "Ezechel " ]
                , span [ class "self-center text-2xl font-semibold font-sans tracking-normal whitespace-nowrap text-neutral-500 dark:text-neutral-500 ml-1" ] [ text "Suciu" ]
                ]
            , div [ class "flex items-center md:order-2" ]
                ([ changeThemeButton model, navbarSeparator ] ++ (languageSwitcher model ++ [ navbarSeparator, facebookButtonIconOnly, menuHandle model ]))
            , div [ class "items-center justify-between hidden w-full md:flex md:w-auto md:order-1", id "navbar-menu" ]
                [ ul [ class "flex flex-col p-4 md:p-0 mt-4 font-medium border border-neutral-200 dark:border-neutral-700 bg-neutral-100 dark:bg-neutral-700 rounded-lg md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-neutral-50 md:dark:bg-neutral-950" ]
                    [ menuElement model Routes.Home
                    , menuElement model Routes.Books
                    , menuElement model Routes.About

                    -- TODO: re-enable Contact page
                    -- , menuElement model Routes.Contact
                    ]
                ]
            ]
        ]


navbarSeparator : Html Msg
navbarSeparator =
    span [ class "px-2" ] [ text "|" ]


menuHandle : Model -> Html Msg
menuHandle model =
    button
        [ type_ "button"
        , attribute "data-collapse-toggle" "navbar-menu"
        , class "inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        , attribute "aria-controls" "navbar-menu"
        , attribute "aria-expanded" "false"
        , id "menu-button"
        ]
        [ span [ class "sr-only" ] [ text (t model.translations "Open main menu") ]
        , menuIcon
        ]


menuElement : Model -> Route -> Html Msg
menuElement model route =
    let
        localizedPageName =
            t model.translations (pageName route)

        activePage =
            isActivePage model.page route
    in
    if activePage then
        li []
            [ a
                [ Routes.href model.currentLanguage route
                , onClick ClickMenuElement
                , class "block py-2 pl-3 pr-4 text-white dark:text-neutral-300 bg-neutral-950 dark:bg-blue-800 rounded md:bg-transparent md:dark:bg-transparent md:text-neutral-950 md:hover:text-neutral-950 md:p-0 md:dark:text-blue-400 md:dark:hover:text-blue-400 uppercase font-semibold"
                , attribute "aria-current" "page"
                ]
                [ text localizedPageName ]
            ]

    else
        li []
            [ a
                [ Routes.href model.currentLanguage route
                , onClick ClickMenuElement
                , class "block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:text-gray-900 md:hover:text-gray-900 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-neutral-300 dark:text-neutral-300 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 uppercase font-normal"
                ]
                [ text localizedPageName ]
            ]


isActivePage : Page -> Route -> Bool
isActivePage page route =
    case pageToRoute page of
        Just r ->
            r == route

        Nothing ->
            False


pageToRoute : Page -> Maybe Route
pageToRoute page =
    case page of
        Home _ ->
            Just Routes.Home

        Books _ ->
            Just Routes.Books

        Book bookModel ->
            Just (Routes.Book bookModel.bookId)

        About _ ->
            Just Routes.About

        Contact _ ->
            Just Routes.Contact

        ShortUrl model ->
            Just (Routes.ShortUrl model.token)

        NotFound ->
            Nothing


pageName : Route -> String
pageName route =
    case route of
        Routes.Home ->
            "Home"

        Routes.Books ->
            "Books"

        -- This does not have a menu element, the value here will not be used
        Routes.Book _ ->
            "Book Details"

        Routes.About ->
            "About"

        Routes.Contact ->
            "Contact"

        -- This does not have a menu element, the value here will not be used
        Routes.ShortUrl token ->
            "ShortUrl = " ++ token


languageSwitcher : Model -> List (Html Msg)
languageSwitcher model =
    let
        currentLanguageMarkup =
            if model.currentLanguage == En then
                [ text "EN" ]

            else
                [ text "RO" ]
    in
    [ button
        [ type_ "button"
        , attribute "data-dropdown-toggle" "language-dropdown-menu"
        , class "inline-flex items-center font-medium justify-center py-2 text-sm text-gray-900 dark:text-gray-200 rounded-lg cursor-pointer hover:bg-transparent dark:hover:bg-transparent dark:hover:text-gray-200"
        , id "language-dropdown-button"
        ]
        currentLanguageMarkup
    , div
        [ class "z-50 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-neutral-950 border-gray-100 dark:border-gray-600 border border-1"
        , id "language-dropdown-menu"
        ]
        [ ul [ class "py-2 font-medium", attribute "role" "none" ] (languageDropdownEntries model)
        ]
    ]


languageDropdownEntries : Model -> List (Html Msg)
languageDropdownEntries model =
    if model.currentLanguage == En then
        [ languageDropdownEntry model Ro ]

    else
        [ languageDropdownEntry model En ]


languageDropdownEntry : Model -> Lang -> Html Msg
languageDropdownEntry model language =
    let
        langText =
            if language == En then
                [ text "EN" ]

            else
                [ text "RO" ]
    in
    li []
        [ button
            [ class "block px-4 py-2 text-sm text-gray-700 hover:bg-transparent dark:text-gray-200 dark:hover:bg-neutral-950 dark:hover:text-gray-200"
            , attribute "role" "menuitem"
            , onClick (ChangeLanguage language)
            ]
            [ div [ class "inline-flex items-center" ] langText ]
        ]


viewFooter : Model -> Html Msg
viewFooter model =
    footer [ class "py-4 sm:py-6 bg-neutral-700 dark:bg-neutral-950 text-white dark:text-neutral-300" ]
        [ div [ class "mx-auto max-w-screen-2xl px-4" ]
            [ div [ class "sm:flex sm:items-center sm:justify-between" ]
                [ span [ class "text-sm sm:text-center" ]
                    [ text "© 2024 "
                    , a [ href "https://ezechelsuciu.me", class "hover:underline" ] [ text "Ezechel Suciu" ]
                    , text (". " ++ t model.translations "All Rights Reserved" ++ ".")
                    ]
                , div [ class "flex mt-4 space-x-6 sm:justify-center sm:mt-0" ]
                    []
                ]
            ]
        ]


changeThemeButton : Model -> Html Msg
changeThemeButton model =
    let
        ( icon, currentTitle, themeAction ) =
            case model.theme of
                Light ->
                    ( moonIcon, "Dark mode", Dark )

                Dark ->
                    ( sunIcon, "Light mode", Light )
    in
    button [ class "text-neutral-950 dark:text-neutral-300", title currentTitle, onClick (ChangeTheme themeAction) ] [ icon ]



---- PROGRAM ----


main : Program Flags Model Msg
main =
    Browser.application
        { init = init
        , view = view
        , update = update
        , subscriptions = always Sub.none
        , onUrlRequest = ClickLink
        , onUrlChange = ChangeUrl
        }
